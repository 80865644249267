<template>
  <!-- 告警信息 -->
  <div class="alarm_box">
    <div class="flexCB" >
      <div>
        <el-input  placeholder="请输入搜索内容" class="contInput" v-model="ectpname"></el-input>
        <el-select v-model="ectptype" placeholder="告警状态" class="utG setSe">
          <el-option
						v-for="item in ectptypeOption"
						:key="item.value"
						:label="item.label"
						:value="item.value">
					</el-option>
        </el-select>
        <el-select v-model="ectpisok" placeholder="处理状态" class="utF setSe">
          <el-option
              v-for="item in ectpisokOption"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="ectpwttl" placeholder="是否逾期" class="utN setSe">
          <el-option
						v-for="item in ectpwttlOption"
						:key="item.value"
						:label="item.label"
						:value="item.value">
					</el-option>
        </el-select>
        <el-select v-model="ectpdisp" placeholder="是否分派" class="utF setSe">
          <el-option
						v-for="item in ectpdispOption"
						:key="item.value"
						:label="item.label"
						:value="item.value">
					</el-option>
        </el-select>
        <el-select v-model="ectpover" placeholder="是否督办" class="utN setSe">
          <el-option
						v-for="item in ectpoverOption"
						:key="item.value"
						:label="item.label"
						:value="item.value">
					</el-option>
        </el-select>
        <el-select v-model="equname" placeholder="设备类型" class="utF setSe">
          <el-option
						v-for="item in equnameOption"
						:key="item.equtypeid"
						:label="item.equtypename"
						:value="item.equtypeid">
					</el-option>
        </el-select>
        <el-button class="searchbtn" @click="handleSearch">搜索</el-button>
        <el-button class="resetbtn" @click="handleReset">重置</el-button>
      </div>
      <div><el-button class="exportbtn" @click="exportHand">导出</el-button></div>
    </div>
    <div class="alarm_table hidTable">
      <el-table
        :data="alarmList"
        style="width: 100%">
        <el-table-column
          label="序号"
          type="index">
        </el-table-column>
        <el-table-column
          label="告警编号"
          prop="ectpid"
          width="200">
          <template slot-scope="scope">
            <span style="color:#42EEFF;cursor: pointer;" @click="goAlarmDetail(scope.row)" >{{scope.row.ectpid}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="告警类型"
          prop="ectptype">
          <template slot-scope="scope">
					  <span v-if="scope.row.ectptype == 1">火警信息</span>
					  <span v-if="scope.row.ectptype == 2">故障</span>
					  <span v-if="scope.row.ectptype == 3">隐患</span>
					  <span v-if="scope.row.ectptype == 4">其他</span>
					</template>
        </el-table-column>
        <el-table-column
          label="设备类型"
          prop="equname">
        </el-table-column>
        <el-table-column
          label="告警时间"
          prop="ectptime"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          label="告警地点"
          prop="adderss"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          label="是否处理"
          prop="ectpisok">
          <template slot-scope="scope">
					  <span v-if="scope.row.ectpisok == 1">否</span>
					  <span v-if="scope.row.ectpisok == 2">是</span>
					</template>
        </el-table-column>
        <el-table-column
          label="是否逾期"
          prop="ectpwttl">
          <template slot-scope="scope">
					  <span v-if="scope.row.ectpwttl == 1">否</span>
					  <span v-if="scope.row.ectpwttl == 2">是</span>
					</template>
        </el-table-column>
        <el-table-column
          label="是否分派"
          prop="ectpdisp">
          <template slot-scope="scope">
					  <span v-if="scope.row.ectpdisp == 1">否</span>
					  <span v-if="scope.row.ectpdisp == 2">是</span>
					</template>
        </el-table-column>
        <el-table-column
          label="是否督办"
          prop="ectpover">
          <template slot-scope="scope">
					  <span v-if="scope.row.ectpover == 1">否</span>
					  <span v-if="scope.row.ectpover == 2">是</span>
					</template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="PageChange"
      :current-page="page"
      :page-sizes="[10]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="indexPage">
    </el-pagination>

    <!--  告警详情  -->
    <el-dialog :visible.sync="alarmDetailShow" class="alarmDetailDialog alarmDialog hdBg" width="1200px" :close-on-click-modal="false">
      <AlarmDetail :alarmKey="alarmKey"
                   @dealHandShow="dealHandShow"
                   @assignHandShow="assignHandShow"
                   @superviseHandShow="superviseHandShow"
                   @misreportHandShow="misreportHandShow"
                   @openImg="openImg"
                   @customerDealShow="customerDealShow"
                   @customerTel="customerTel"
                   @busGzDeal="busGzDeal"
                   @ysDealShow="ysDealShow">
      </AlarmDetail>
    </el-dialog>
    <!-- 告警详情 弹窗 AI放大图片 -->
    <el-dialog title="抓拍图片" :visible.sync="showBigImg" center width="1000px" class="hdBg hdTb" style="z-index: 999;">
      <div class="bigImgBox">
        <div class="sizeBox" @mousewheel.prevent="rollImg">
          <img :src="bigImgUrl" alt="" class="img" ref="imgDiv" :onerror="notImg" @mousedown="ImgMove"/>
        </div>
      </div>
    </el-dialog>

    <!-- 处理 -->
    <el-dialog title="处理" :visible.sync="dealWithShow" class="dealDialog hdBg hdTb" width="600px" :close-on-click-modal="false">
      <DealWith :alarmOperate="alarmOperate"
                @dealHandCancel="dealHandCancel"
                @dealHandConfirm="dealHandConfirm">
      </DealWith>
    </el-dialog>

    <!-- 分派 -->
    <el-dialog title="分派" :visible.sync="assignShow" class="dealDialog hdBg hdTb" width="600px" :close-on-click-modal="false">
      <Assign :alarmOperate="alarmOperate"
              @assignHandCancel="assignHandCancel"
              @assignHandConfirm="assignHandConfirm">
      </Assign>
    </el-dialog>

     <!-- 督办 -->
    <el-dialog title="督办" :visible.sync="superviseShow" class="dealDialog hdBg hdTb" width="600px" :close-on-click-modal="false">
      <Supervise :alarmOperate="alarmOperate"
              @superHandCancel="superHandCancel"
              @superHandConfirm="superHandConfirm">
      </Supervise>
    </el-dialog>

    <!--  客服-处理-弹框  -->
    <el-dialog title="处理" :visible.sync="customerDealWith" class="dealDialog hdBg hdTb" width="600px" :close-on-click-modal="false">
      <CusDealWith :alarmInfo="alarmInfo" @dealClose="dealClose" @busDealSuccess="busDealSuccess"></CusDealWith>
    </el-dialog>

    <!-- 客服-延时处理-弹框 -->
    <el-dialog title="延时处理" :visible.sync="ysDealDialog" class="dealDialog hdBg hdTb" width="600px" :close-on-click-modal="false">
      <Delayed :alarmInfo="alarmInfo" @delayedClose="delayedClose" @delayedSuccess="delayedSuccess"></Delayed>
    </el-dialog>
  </div>
</template>

<script>
import { getalarmInfo, alarmReport, getEquipExport } from '@/http/api/alarm'
import AlarmDetail from '@/components/home/alarmDetail'
import DealWith from '@/components/home/dealWith'
import Assign from '@/components/home/assign'
import Supervise from '@/components/home/supervise'
import CusDealWith from '@/components/customer/dealWith.vue'
import Delayed from '@/components/customer/delayed.vue'
import { getEquipType } from '@/http/api/equip'

export default {
  props: ['unitId'],
  components: {
    AlarmDetail,
    DealWith,
    Assign,
    Supervise,
    CusDealWith, // 客服处理
    Delayed
  },
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      alarmList: [],
      ectpname: '',
      ectptype: '',
      ectpisok: '',
      ectpwttl: '',
      ectpdisp: '',
      ectpover: '',
      equname: '',
      equnameOption: [],
      ectptypeOption: [
        { label: '全部', value: '0' },
        { label: '火警信息', value: '1' },
        { label: '故障', value: '2' },
        { label: '隐患', value: '3' },
        { label: '其他', value: '4' }
      ],
      ectpisokOption: [
        { label: '全部', value: '0' },
        { label: '待处理', value: '1' },
        { label: '已处理', value: '2' }
      ],
      ectpwttlOption: [
        { label: '全部', value: '0' },
        { label: '否', value: '1' },
        { label: '是', value: '2' }
      ],
      ectpdispOption: [
        { label: '全部', value: '0' },
        { label: '未分派', value: '1' },
        { label: '已分派', value: '2' }
      ],
      ectpoverOption: [
        { label: '全部', value: '0' },
        { label: '未督办', value: '1' },
        { label: '已督办', value: '2' }
      ],
      alarmDetailShow: false,
      alarmKey: '',
      dealWithShow: false,
      alarmOperate: '',
      assignShow: false,
      superviseShow: false,
      showBigImg: false,
      notImg:  'this.src="'+ require('@/assets/img/imgError.png') +'"',
      bigImgUrl: '',
      customerDealWith: false,
      alarmInfo: '',
      ysDealDialog: false
    }
  },
  watch: {
    unitId (val) {
      this.companyid = val
      this.init()
    }
  },
  created () {
    this.roleid = JSON.parse(window.localStorage.getItem('loginInfo')).roleid
    if (this.roleid === '12') {
      this.companyid = this.unitId
    } else {
      this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    }
    this.init()
  },
  methods: {
    init () {
      const params = {
        page_index: this.page,
        page_size: this.size,
        ectpname: this.ectpname,
        ectptype: this.ectptype,
        ectpisok: this.ectpisok,
        ectpwttl: this.ectpwttl,
        companyid: this.companyid,
        ectpdisp: this.ectpdisp,
        ectpover: this.ectpover,
        ectpstime: this.ectpstime,
        ectpetime: this.ectpetime,
        equtype: this.equname
      }
      getalarmInfo(params).then(res => {
        if (res.status === '1') {
          this.alarmList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.alarmList = []
          this.total = 0
        }
      })

      getEquipType({}).then(res => {
        if (res.status === '1') {
          this.equnameOption = res.data.data
        } else {
          this.equnameOption = []
        }
      })
    },
    // 告警处理
    goAlarmDetail (row) {
      this.alarmDetailShow = true
      this.alarmKey = row
    },
    // 展示处理
    dealHandShow (res) {
      this.dealWithShow = true
      this.alarmOperate = res
    },
    // 处理关闭
    dealHandCancel () {
      this.dealWithShow = false
    },
    // 处理提交
    dealHandConfirm () {
      this.dealWithShow = false
      this.alarmDetailShow = false
      this.init()
    },
    // 分派展示
    assignHandShow (res) {
      this.assignShow = true
      this.alarmOperate = res
    },
    // 分派关闭
    assignHandCancel () {
      this.assignShow = false
    },
    // 分派提交
    assignHandConfirm () {
      this.assignShow = false
      this.alarmDetailShow = false
      this.init()
    },
    // 督办展示
    superviseHandShow (res) {
      this.superviseShow = true
      this.alarmOperate = res
    },
    // 督办关闭
    superHandCancel () {
      this.superviseShow = false
    },
    // 督办提交
    superHandConfirm () {
      this.superviseShow = false
      this.alarmDetailShow = false
      this.init()
    },
    /* 客服-处理-(火警-隐患)-展示 */
    customerDealShow (res) {
      this.customerDealWith = true
      this.alarmInfo = res
    },
    /* 客服-处理-(火警-隐患)-取消 */
    dealClose () {
      this.customerDealWith = false
    },
    /* 客服-处理-(火警-隐患)-提交 */
    busDealSuccess () {
      this.customerDealWith = false
      this.alarmDetailShow = false
      this.init()
    },
    /* 客服-电核 */
    customerTel () {
      this.alarmDetailShow = false
      this.init()
    },
    /* 客服-处理-(故障) */
    busGzDeal () {
      this.alarmDetailShow = false
      this.init() //刷新
    },
    /* 客服-延时处理-(隐患)-展示 */
    ysDealShow (res) {
      this.ysDealDialog = true
      this.alarmInfo = res
    },
    /* 客服-延时处理-(隐患)-取消*/
    delayedClose () {
      this.ysDealDialog = false
    },
    /* 客服-延时处理-(隐患)-提交*/
    delayedSuccess () {
      this.ysDealDialog = false
      this.alarmDetailShow = false
      this.init() //刷新
    },
    // 误报
    misreportHandShow (res) {
      this.alarmDetailShow = false
      this.Refresh = !this.Refresh
      // this.$confirm('点击误报后本条告警信息将会自动标记为误报信息，请确定是否误报?', '误报', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      //   customClass: 'Misinform',
      //   center: true
      // }).then(() => {
      //   alarmReport({ ectpid: res.ectpid }).then(res => {
      //     if (res.status === '1') {
      //       this.$message.success(res.message)
      //       this.alarmDetailShow = false
      //       this.Refresh = !this.Refresh
      //       if (this.isAlarmFinish === true) {
      //         this.getCompanyInfo()
      //       }
      //     } else {
      //       this.$message.success(res.message)
      //     }
      //   })
      // }).catch(() => {})
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.page = 1
      this.init()
    },
    handleReset () {
      this.page = 1
      this.ectpname = ''
      this.ectptype = ''
      this.ectpisok = ''
      this.ectpwttl = ''
      this.ectpdisp = ''
      this.ectpover = ''
      this.equname = ''
      this.init()
    },
    // 导出
    exportHand () {
      const params = {
        ectpname: this.ectpname,
        ectptype: this.ectptype,
        ectpisok: this.ectpisok,
        ectpwttl: this.ectpwttl,
        companyid: this.companyid,
        ectpdisp: this.ectpdisp,
        ectpover: this.ectpover,
        equtype: this.equname
      }
      getEquipExport(params).then(res => {
        if (res.status === '1') {
          this.$message.success(res.message)
          window.open(res.data.filepath)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    /* Ai摄像 */
    openImg (res) {
      this.showBigImg = true
      this.bigImgUrl = res
      this.$nextTick(() => {
        this.$refs.imgDiv.style.left = '0px'
        this.$refs.imgDiv.style.top = '0px'
        this.$refs.imgDiv.style.zoom = "100%"
      })
    },
    rollImg (event) {
      var zoom = parseInt(this.$refs.imgDiv.style.zoom) || 100
      zoom += event.wheelDelta / 12
      if (zoom >= 80 && zoom <500) {
        this.$refs.imgDiv.style.zoom = zoom + "%"
      }
      return false
    },
    ImgMove (e) {
      e.preventDefault()
      var left = document.querySelector('.sizeBox')
      var img = document.querySelector('.img')
      var x = e.pageX - img.offsetLeft
      var y = e.pageY - img.offsetTop
      left.addEventListener('mousemove', move)
      function move(e) {
        img.style.left = e.pageX - x + 'px'
        img.style.top = e.pageY - y + 'px'
      }
      img.addEventListener('mouseup', function() {
        left.removeEventListener('mousemove', move)
      })
      left.addEventListener('mouseout', function() {
        left.removeEventListener('mousemove', move)
      })
    }
  }
}
</script>

<style scoped>
.alarm_box {
  width: 100%;
  height: 100%;
}
.alarm_table {
  margin-top: 20px;
}
.utF {
  margin: 0 16px;
}
.searchbtn {
  margin-left: 16px;
}
.contInput .el-input__inner{
  background: rgba(136,172,252,0.16);
  border: none;
  color: #A5B7CC;
}
</style>
