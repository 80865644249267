<template>
  <div class="pro_list">
    <!-- 设备列表 -->
    <div class="rig_title">
      <i class="el-icon-caret-right" style="margin:0 10px;"></i>
      <p>{{equKey.equtypename}}</p>
      <span class="back" @click="goBack" >返回</span>
    </div>
    <div class="alarm_box">
      <div class="flexCB" >
        <div>
          <el-input  placeholder="请输入搜索内容" class="contInput" v-model="equname"></el-input>
          <el-select v-model="equcfonf" placeholder="设备状态" class="utG setSe">
            <el-option
              v-for="item in equcfonfOption"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-button class="searchbtn" @click="handleSearch">搜索</el-button>
          <el-button class="resetbtn" @click="handleReset">重置</el-button>
        </div>
      </div>
      <div class="pro_table hidTable">
        <el-table
          :data="proList"
          style="width: 100%">
          <el-table-column
            label="序号"
            type="index">
          </el-table-column>
          <el-table-column
            label="设备编号"
            prop="equimei">
            <template slot-scope="scope">
              <span class="online" @click="goProjectDetail(scope.row)" >{{scope.row.equimei}}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="设备状态"
            prop="equcfonf">
            <template slot-scope="scope">
              <el-tag class="off_line" v-if="scope.row.equcfonf == 1">离线</el-tag>
              <el-tag class="on_line" v-if="scope.row.equcfonf == 2">在线</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="安装位置"
            show-overflow-tooltip
            prop="adderss">
          </el-table-column>
          <el-table-column
            label="设备名称"
            prop="equname">
          </el-table-column>
          <el-table-column
            label="更新时间"
            prop="equcfstime">
          </el-table-column>
          <el-table-column
            label="报警状态"
            prop="equisok">
            <template slot-scope="scope">
              <span v-if="scope.row.equisok == 1">正常</span>
              <span v-if="scope.row.equisok == 2">故障</span>
              <span v-if="scope.row.equisok == 3">隐患</span>
            </template>
          </el-table-column>
          <el-table-column
            label="安全管理人及电话"
            prop="username,userphone">
            <template slot-scope="scope">
              {{scope.row.username}} | {{scope.row.userphone}}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @current-change="PageChange"
        :current-page="page"
        :page-sizes="[10]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        class="indexPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getEquipList } from '@/http/api/equip'

export default {
  props:['equKey', 'unitId', 'refreshPage'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      equname: '',
      proList: [],
      equcfonf: '',
      equcfonfOption: [
        {
          label: '全部状态',
          value: ''
        },
        {
          label: '在线',
          value: 2
        },
        {
          label: '离线',
          value: 1
        }
      ],
    }
  },
  watch: {
    unitId (val) {
      this.companyid = val
      this.init()
    }
  },
  created () {
    this.roleid = JSON.parse(window.localStorage.getItem('loginInfo')).roleid
    if (this.roleid === '12') {
      this.companyid = this.unitId
    } else {
      this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    }
    this.page = this.refreshPage
    this.init()
  },
  methods: {
    init () {
      const params = {
        page_index: this.page,
        page_size: this.size,
        equname: this.equname,
        equtype: this.equKey.equtypeid,
        equcfonf: this.equcfonf,
        companyid: this.unitId
      }
      getEquipList(params).then(res => {
        if (res.status === '1') {
          this.proList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.proList = []
          this.total = 0
        }
      })
    },
    goProjectDetail (row) {
      this.$emit('goProjectDetail',row, this.page)
    },
    goBack () {
      this.$emit('back')
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.page = 1
      this.init()
    },
    handleReset () {
      this.page = 1
      this.equname = ''
      this.equcfonf = ''
      this.init()
    }
  }
}
</script>

<style scoped>
.pro_list {
  width: 100%;
  height: 100%;
  padding: 20px 40px 0 40px;
  box-sizing: border-box;
  position: relative;
  align-items: center;
  overflow: auto;
}
.alarm_box {
  margin-top: 30px;
  position: relative;
  width: 100%;
}
.searchbtn {
  margin-left: 16px;
}
.planPage,.pro_table{
  width: 100%;
}
.online {
  color: #42EEFF;
  cursor: pointer;
}
.el-tag {
  background-color: transparent;
  color: #FFFFFF;
}
.on_line {
  width: 53px;
  height: 24px;
  background: rgba(82, 196, 26, 0.1);
  border: 1px solid rgba(82, 196, 26, 0.6);
  opacity: 1;
  border-radius: 4px;
  color: #52C41A;
  line-height: 24px;
  text-align: center;
}
.off_line {
  width: 53px;
  height: 24px;
  background: rgba(245, 34, 45, 0.1);
  border: 1px solid rgba(245, 34, 45, 0.6);
  opacity: 1;
  border-radius: 4px;
  color: #F5222D;
  line-height: 24px;
  text-align: center;
}
</style>
