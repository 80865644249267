<template>
  <div class="deal_cont">
    <el-form :model="form" :rules="formRules" ref="form" class="alarmHForm">
      <el-form-item label="处理人" prop="dealuser" class="afItem">
        <el-select v-model="form.dealuser" placeholder="请选择处理人" class="afInput">
          <el-option
              v-for="item in userList"
              :key="item.userid"
              :label="item.username"
              :value="item.userid">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="处理时间" prop="dealtime" class="afItem">
        <el-date-picker
            class="afInput"
            v-model="form.dealtime"
            type="datetime"
            placeholder="选择时间"
            default-time="12:00:00">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="处理内容" prop="dealmark" class="afItem">
        <textarea v-model="form.dealmark" class="OperTextareas" placeholder="请输入处理内容" maxlength="150"></textarea>
        <p class="OperText"><span>{{ form.dealmark.length }}</span>/150</p>
      </el-form-item>
      <el-form-item label="处理附件" class="afItem afImg">
        <Upload :fileType="'jpg/png/jpeg'" class="upImg" @uploadSuccess="ImgSuccess"></Upload>
        <span class="uptip">只支持.jpg.png.jpeg 格式</span>
      </el-form-item>
      <div class="flexCB">
        <el-form-item label="同类型告警处理" class="afItem afRadio">
          <div class="alarmRadio">
            <el-radio v-model="form.dealisok" label="1">是</el-radio>
            <el-radio v-model="form.dealisok" label="0">否</el-radio>
          </div>
        </el-form-item>
        <div class="flexAC">
          <div class="afCancel" @click="handCancel()">取消</div>
          <el-button class="afConfirm" @click="handConfirm()" :disabled="btnDisabled">确定</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { userQuery } from '@/http/api/user'
import { alarmDeal } from '@/http/api/alarm'
import Upload from '@/components/upload.vue'
import { getNowTime } from '@/utils/validate'
export default {
  components: {
    Upload
  },
  props: ['alarmOperate'],
  data () {
    return {
      companyid: '',
      userList: [],
      form: {
        dealuser: '',
        dealtime: new Date(),
        dealmark: '',
        dealfile: '',
        dealisok: '0'
      },
      formRules: {
        dealuser: [{ required: true, trigger: 'blur', message: '请选择处理人' }],
        dealtime: [{ required: true, trigger: 'blur', message: '请选择处理时间' }],
        dealmark: [{ required: true, trigger: 'blur', message: '处理内容不能为空' }]
      },
      btnDisabled: false
    }
  },
  watch: {
    alarmOperate (val) {
      this.getUserListS()
    }
  },
  created () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    this.getUserListS()
  },
  methods: {
    // 用户列表
    getUserListS () {
      userQuery({ companyid: this.companyid }).then(res => {
        if (res.status === '1') {
          this.userList = res.data.data
          this.form.dealuser = JSON.parse(window.localStorage.getItem('loginInfo')).userid
        } else {
          this.userList = []
        }
      })
    },
    ImgSuccess (res) {
      this.form.dealfile = res.filepath
    },
    handCancel () {
      this.$refs.form.resetFields()
      this.$emit('dealHandCancel')
    },
    handConfirm () {
      this.$refs.form.validate((valid) => {
        if (!valid) return
        this.form.dealtime = getNowTime(this.form.dealtime)
        const params = {
          ectpid: this.alarmOperate.ectpid,
          dealuser: this.form.dealuser,
          dealtime: this.form.dealtime,
          dealmark: this.form.dealmark,
          dealfile: this.form.dealfile,
          dealisok: this.form.dealisok
        }
        this.btnDisabled = true
        alarmDeal(params).then(res => {
          if (res.status === '1') {
            this.btnDisabled = false
            this.$emit('dealHandConfirm')
            this.$message.success(res.message)
            this.$refs.form.resetFields()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.deal_cont{
  width: 100%;
  height: 100%;
  position: relative;
}
.upImg{
  width: 500px;
  height: 100px;
  padding-top: 24px;
}
.upImg>>>.uploader{
  background: rgba(136, 172, 252, 0.16);
}
.uptip{
  color: #A5B7CC;
  font-size: 13px;
}
.afRadio{
  margin-bottom: 0px;
}
.afRadio>>>.el-form-item__content{
  line-height: 22px;
}
.el-button:focus, .el-button:hover{
  background: rgba(136, 172, 252, 0.16);
  border: 1px solid rgba(136, 172, 252, 0.16);
  color: #ffad42;
}
.el-button.is-disabled, .el-button.is-disabled:focus, .el-button.is-disabled:hover{
  color: #C0C4CC;
  background: rgba(136, 172, 252, 0.16);
}
</style>
