<template>
<!-- 单位联网 单位概况 -->
  <div class="unit_container">
    <!-- 告警列表 -->
    <div class="alarm_list" v-if="!isShowList && !isShowDetail">
      <div class="alarm_info">
        <div class="rig_title"><i class="el-icon-caret-right" style="margin:0 10px;"></i><p>告警列表</p></div>
        <!-- <div class="alarm_search">
          <el-input placeholder="请输入内容" class="contInput"></el-input>
          <el-button class="contsearch">搜索</el-button>
        </div> -->
        <div class="step_box">
          <el-steps direction="vertical" :active="Number(activeNames)">
            <el-step
              class="step"
              v-for="i in stepList"
              :key="i.index">
              <template slot="description">
                <div class="description">
                  <div class="descriptionText">
                    <el-collapse v-model="activeNames" accordion @change="handleChange">
                      <el-collapse-item :title="i.time" >
                        <div v-for="item in i.data" :key="item.index" class="info_box">
                          <p>
                            <span class="info_one">{{ item.ectptime }}</span>
                            <span class="info_two">{{ item.equname }}</span>
                            <span class="info_four">{{ item.ectpmark }}</span>
                          </p>
                          <span class="info_three" v-if="item.ectptype == 1">火警信息</span>
                          <span class="info_three" v-if="item.ectptype == 2">故障</span>
                          <span class="info_three" v-if="item.ectptype == 3">隐患</span>
                          <span class="info_three" v-if="item.ectptype == 4">其他</span>
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                  </div>
                </div>
              </template>
            </el-step>
          </el-steps>
        </div>
      </div>
    </div>
    <!-- 单位信息 -->
    <div class="unitr_info" v-if="!isShowList && !isShowDetail">
      <div class="company_info">
        <div class="rig_title"><i class="el-icon-caret-right" style="margin:0 10px;"></i><p>单位信息</p></div>
        <div class="unit_info">
          <div class="ui_img">
            <img class="ui_logo" v-if="companyInfo.companyimage != '' " :src="companyInfo.companyimage"/>
            <img class="ui_logo" v-if="companyInfo.companyimage == '' "  src="../../assets/img/unit_default.png" />
          </div>
          <div class="ui_cont">
            <!-- <div class="ui_title"><div class="title_line"></div><div>单位基本信息</div></div> -->
            <el-row>
              <el-col :span="11">
                <!-- <p>单位名称：{{companyInfo.companyname}}</p>
                <p>单位电话：{{companyInfo.companyphone}}</p>
                <p>单位等级：{{companyInfo.companylevelname}}</p>
                <p>单位法人：{{companyInfo.companyuser}}</p>
                <p>辖区消防队：{{companyInfo.firename}}</p> -->
                <p>单位名称: {{companyInfo.companyname}}</p>
                <p>行业性质: {{companyInfo.industryname}}</p>
                <p>单位等级: {{companyInfo.companylevelname}}</p>
                <p>单位法人: {{companyInfo.companyuser}}</p>
                <p>消控室电话: {{companyInfo.companyphone}}</p>
              </el-col>
              <el-col :span="13">
                <!-- <p>统一信用码：{{companyInfo.companycode}}</p>
                <p>单位地址：{{companyInfo.companyress}}</p>
                <p>行业性质：{{companyInfo.industryname}}</p>
                <p>法人电话：{{companyInfo.suser_phone}}</p>
                <p>维保单位：{{companyInfo.mainname}}</p> -->
                <p>统一信用码: {{companyInfo.companycode}}</p>
                <p>单位地址: {{companyInfo.companyress}}</p>
                <p>辖区消防队: {{companyInfo.firename}}</p>
                <p>法人电话: {{companyInfo.suser_phone}}</p>
                <p>安全负责人电话: {{companyInfo.securityphone}}</p>
              </el-col>
            </el-row>
          </div>
          <div class="ui_optimi">
            <div class="ui_p" @click="showGrad"></div>
            <div class="ui_score">
              <ScoreLoading v-if="ScoreShow" :isResult="isResult" :companyScore="companyScore" @clear="clear"></ScoreLoading>
              <!-- <span>{{companyInfo.companyscore}}</span>分 -->
            </div>
            <div class="uiBtn" @click="handleScore">一键评分</div>
          </div>
        </div>
      </div>

      <div class="company_equip">
        <div class="eq_title">
          <div class="er_item" @click="tabClick(1)" :class="{'selected': tabId == 1}">设备信息</div>
          <div class="er_item" @click="tabClick(2)" :class="{'selected': tabId == 2}">微消防站</div>
          <div class="er_item" @click="tabClick(3)" :class="{'selected': tabId == 3}">巡检信息</div>
          <div class="er_item" @click="tabClick(4)" :class="{'selected': tabId == 4}">消防预案</div>
          <div class="er_item" @click="tabClick(5)" :class="{'selected': tabId == 5}">告警信息</div>
          <div class="er_item" @click="tabClick(6)" :class="{'selected': tabId == 6}">报表统计</div>
        </div>
        <div class="eqCont">
          <Eqinfo v-if="tabId == 1" @lookEquList='lookEquList'></Eqinfo>
          <Firestation v-if="tabId == 2"></Firestation>
          <!-- <Check v-if="tabId == 3"></Check> -->
          <CheckPlan v-if="tabId == 3"></CheckPlan>
          <Fireplan v-if="tabId == 4"></Fireplan>
          <Alarminfo v-if="tabId == 5"></Alarminfo>
          <ImportFrom v-if="tabId == 6"></ImportFrom>
        </div>
      </div>
    </div>

    <!-- 设备列表 -->
    <div class="list_container" v-if='isShowList && !isShowDetail'>
      <ProEquList  @back="back" :equKey="equKey" :refreshPage="refreshPage" @goProjectDetail="goProjectDetail(arguments)"></ProEquList>
    </div>
    <!-- 设备详情 -->
    <div class="detail_container" v-if='isShowDetail'>
      <!-- <ProDetail @goback="goback" :equDeatil="equDeatil"  v-if="typeId != '1'&& typeId != '2' && typeId != '20'"></ProDetail>-->
      <ProDetail @goback="goback" :equDeatil="equDeatil"  v-if="equipIdList(typeId)"></ProDetail>
      <!--  视频联动  -->
      <Monitor v-if="typeId === '1'" :equDeatil="equDeatil"  @goback="goback"></Monitor>
      <!--  萤石云可视对讲  -->
      <Visual v-if="typeId === '2'" :equDeatil="equDeatil"  @goback="goback"></Visual>
      <!--  AI摄像头  -->
      <Aicamera v-if="typeId === '20'" :equDeatil="equDeatil"  @goback="goback"></Aicamera>
      <!--  智能断路器  -->
      <Breaker v-if="typeId === '21'" :equDeatil="equDeatil"  @goback="goback"></Breaker>
    </div>
    <!-- 评分规则 -->
    <el-dialog title="单位评分" :visible.sync="gardDialog" center width="910px" class="scoreDialog hdBg hdTb">
      <div class="rig_title"><i class="el-icon-caret-right" style="margin:0 10px;"></i><p>单位评分规则</p></div>
      <div class="score_t">
        <span style="font-size: 18px; color: #FFFFFF; font-weight: 500;">{{ companyInfo.companyname }}: </span>
        <!-- <span style="font-size: 18px; color: #196DF7; font-weight: 900;"></span> --><span style="font-size: 18px; color: #FFAD42; font-weight: 900;">{{ companyInfo.companyscore }}分</span>
      </div>
      <ul>
        <li class="lis">
          <div class="one">状态</div>
          <div class="two">类型</div>
          <div class="three">分值</div>
          <div class="four">得分</div>
        </li>
        <li v-for="item in scoreList"  :key="item.index" :class="item.typedil? 'lisOther': 'lis'">
          <div class="one">
            <img  v-if="item.isok == true"  src="../../assets/img/wan.png" alt="">
            <img  v-if="item.isok == false" src="../../assets/img/yichang.png" alt="">
          </div>
          <div class="two">{{ item.type }}<p class="two_t">{{ item.typedil }}</p></div>
          <div class="three">{{ item.peak }}</div>
          <div style="font-size: 14px; color: #FFAD42; font-weight: bold;" class="four">{{ item.sorce }}</div>
        </li>
      </ul>
      <div class="text">
        <span style="margin-left:20px;">提示: 安全评分标准判断单位最低分数为 0分 最高分数为 100分。</span>
        <img  src="../../assets/img/wan.png" alt="" ><span>表示状态正常无需处理</span>
        <!-- <p style="margin-left: 50px;">常无需处理</p> -->
        <img  src="../../assets/img/yichang.png" alt="" ><span>表示状态异常需进行及时处理</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Eqinfo from '@/components/work/eqinfo.vue'
import Firestation from '@/components/work/firestation.vue'
// import Check from '@/components/work/check.vue'
import CheckPlan from '@/components/work/checkPlan.vue'
import Fireplan from '@/components/work/fireplan.vue'
import Alarminfo from '@/components/work/alarminfo.vue'
import ProEquList from '@/components/work/proEqulist.vue'
import ProDetail from '@/components/work/proDetail.vue'
import Monitor from '@/components/work/monitor.vue'
import Visual from '@/components/work/visual.vue'
import ImportFrom from '@/components/work/importFrom.vue'
import ScoreLoading from '@/components/work/scoreLoading'
import Aicamera from '@/components/work/Aicamera.vue'
import Breaker from '@/components/work/breaker'
import { getcomDetails, getAlarmInfo } from '@/http/api/work'
import { getcompanySorce } from '@/http/api/unit'

export default {
  name: 'workUnit',
  components: {
    Eqinfo,
    Firestation,
    // Check,
    Fireplan,
    Alarminfo,
    ProEquList,
    ProDetail,
    Monitor,
    Visual,
    ImportFrom,
    ScoreLoading,
    CheckPlan,
    Aicamera,
    Breaker
  },
  data () {
    return {
      tabId: 1,
      companyInfo: {},
      isShowList: false,
      equKey:null,
      isShowDetail: false,
      equDeatil: null,
      isShowHls: false,
      isShowEZU: false,
      typeId: '',
      activeNames: '1',
      active: 0,
      stepList: [],
      gardDialog: false,
      scoreList: [],
      isResult: 0,
      ScoreShow: false,
      companyScore: '',
      refreshPage: 1,
      scrollHeight: 0,
    }
  },
  mounted () {
    // const rigHeight = this.$refs.rightCont.offsetHeight
    // const unitHeight = this.$refs.unitInfoHeight.offsetHeight
    // this.scrollHeight = rigHeight - unitHeight - 160
  },
  created () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    this.init()
  },
  methods: {
    init () {
      getcomDetails({ companyid: this.companyid }).then(res => {
        if (res.status === '1') {
          this.companyInfo = res.data
          this.companyScore = res.data.companyscore
          this.ScoreShow = true
        } else {
          this.companyInfo = ''
        }
      })

      getAlarmInfo({ companyid: this.companyid }).then(res => {
        if (res.status === '1') {
          this.stepList = res.data
        } else {
          this.stepList = []
        }
      })
    },
    tabClick (type) {
      this.tabId = type
    },
    lookEquList (res) {
      this.isShowList = true
      this.refreshPage = 1
      this.equKey = res
    },
    back () {
      this.isShowList = false
    },
    goback () {
      this.isShowDetail = false
    },
    goProjectDetail (res) {
      this.isShowDetail = true
      this.equDeatil = res[0]
      this.typeId = res[0].equtype
      this.refreshPage = res[1]
    },
    handleScore () {
      this.isResult = 1
    },
    clear () {
      this.isResult = 0
    },
    // 步骤条
    handleChange () {
      if (this.active++ > 3) {
        this.active = 0
        this.activeNamesNum = 0
      } else {
        this.activeNamesNum++
      }
    },
    // 评分规则
    showGrad () {
      this.gardDialog = true
      getcompanySorce({ companyid: this.companyid }).then(res => {
        if (res.status === '1') {
          this.scoreList = res.data
        } else {
          this.scoreList = []
        }
      })
    },
    equipIdList(val) { // 设备类型id组合
      var arr = ['1', '2', '20', '21']
      var isShow = false
      if (arr.indexOf(val) === -1) {
        isShow = true
      } else {
        isShow = false
      }
      return isShow
    },
  }
}
</script>

<style scoped>
.unit_container {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 70px;
  box-sizing: border-box;
  background: url('../../assets/unit_img/1.png') no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
}
.list_container {
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  background: url('../../assets/unit_img/1.png') no-repeat;
  background-size: 100% 100%;
}
.detail_container {
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  background: url('../../assets/unit_img/1.png') no-repeat;
  background-size: 100% 100%;
  overflow-y: scroll;
  scrollbar-width: none;  /*火狐兼容*/
}
.detail_container::-webkit-scrollbar {
  width: 0px; /* 谷歌兼容 */
}
.alarm_list {
  width: 23%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  padding: 15px;
  box-sizing: border-box;
  overflow-y: scroll;
  scrollbar-width: none;  /*火狐兼容*/
}
.alarm_list::-webkit-scrollbar {
  width: 0px; /* 谷歌兼容 */
}
.unitr_info {
  width: 83%;
  padding: 15px 30px;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  padding: 15px;
  box-sizing: border-box;
  overflow-y: scroll;
  scrollbar-width: none;  /*火狐兼容*/
}
.unitr_info::-webkit-scrollbar {
  width: 0px; /* 谷歌兼容 */
}
.company_info {
  width: 100%;
  height: auto;
  position: relative;
}
/*.rig_title {*/
/*  width: 100%;*/
/*  height: 30px;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  color: #FFFFFF;*/
/*  font-size: 16px;*/
/*  font-weight: 500;*/
/*  line-height: 30px;*/
/*  background: linear-gradient(91deg, rgba(33, 110, 243, 0.5) 0%, rgba(36, 104, 240, 0) 100%);*/
/*}*/
.ui_title {
  width: 100%;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 20px;
}
.title_line{
  width: 4px;
  height: 16px;
  background: #196DF7;
  margin-right: 10px;
}
.unit_info {
  width: 100%;
  display: flex;
  margin-top: 40px;
  font-size: 14px;
}
.ui_logo {
  border-radius: 4px;
}
.ui_logo{
  width: 170px;
  height: 170px;
}
.ui_cont{
  width: 60%;
  color: #fff;
  padding: 0 15px;
  box-sizing: border-box;
  margin-left: 20px;
}
.ui_cont p{
  margin: 0px 0px 15px;
}
.ui_optimi {
  width: 30%;
  margin-top: 20px;
  position: relative;
}
.ui_p {
  width: 26px;
  height: 26px;
  background: url("../../assets/unit_img/4.png") no-repeat;
  position: absolute;
  left: 100px;
  top: 0;
  cursor: pointer;
}
.ui_score{
  width: 110px;
  height: 110px;
  font-size: 14px;
  color: #A5B7CC;
}
.ui_score span{
  font-size: 50px;
  font-weight: 700;
  color: #42EEFF;
}
.uiBtn{
  width: 90px;
  margin-left: 10px;
  margin-top: 10px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #4295FF;
  font-size: 14px;
  background: url("../../assets/img/unit_btn.png") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}
.company_equip {
  width: 100%;
  height: auto;
  position: relative;
}
.eq_title {
  width: 100%;
  height: 40px;
  background: rgba(136,172,252,0.16);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 25px;
  margin: 40px 0;
}
.eqCont{
  width: 100%;
  height: auto;
  overflow-y: scroll;
  scrollbar-width: none;  /*火狐兼容*/
}
.eqCont::-webkit-scrollbar {
  width: 0px; /* 谷歌兼容 */
}
.er_item {
  padding: 0 30px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}
.selected {
  background: rgba(136,172,252,0.16);
  border-radius: 25px;
  color: #FFAD42;
}
.alarm_search {
  width: 100%;
  height: 35px;
  margin: 20px 0;
  display: flex;
  position: relative;
}
.contsearch {
  border-radius: 0px 30px 30px 0px;
}
/* 步骤条 */
.step {
  width: 100%;
}
.step_box {
  margin-top: 20px;
}
.step_box >>> .el-step__description{
  padding-right: 0%;
}
.step_box >>> .el-step__icon.is-text{
  width: 8px;
  height: 8px;
  background: #42EEFF;
  border-color: #4295FF;
}
.step_box >>> .el-step.is-vertical .el-step__head{
  padding-top: 10px;
}
.step_box >>> .el-step__icon-inner{
  display: none;
}
.step_box >>> .is-vertical .el-step__line{
  left: 3px;
  top: 20px;
  bottom: -14px;
  background-color: rgba(136, 172, 252, 0.16);
}
.step_box >>> .el-collapse-item__header {
  background: transparent;
  color: #fff;
  border: none;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}
.step_box >>> .el-collapse{
  border: none;
}
.step_box >>> .el-collapse-item__wrap {
  background: transparent;
  border: none;
}
.step_box >>> .el-collapse-item__content{
  color: #fff;
  font-size: 14px;
}
.info_box {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
}
.info_two {
  margin: 0 10px;
}
.info_three {
  color: #FF4C4C;
}
/* 评分规则 */
.scoreDialog >>> .el-dialog__body {
  padding: 30px;
}
ul {
  padding: 0;
}
.lis,.lisOther {
  width: 850px;
  height: 40px;
  line-height: 40px;
  background: rgba(136, 172, 252, 0.16);
  border-radius: 4px 4px 0px 0px;
  display: flex;
  font-weight: 500;
  color: #FFFFFF;
  font-size: 14px;
  margin-bottom: 2px;
}
.lisOther{
  height: auto;
  line-height: 20px;
  padding: 8px 0px;
  align-items: center;
}
.score_t {
  margin: 20px 0;
}
.lis img,.lisOther img {
  width: 16px;
  height: 16px;
  margin: auto 0;
  text-align: center;
}
.one {
  width: 20%;
  text-align: center;
}
.two {
  width: 70%;
  text-align: left;
}

.three {
  width: 20%;
  text-align: center;
}
.four {
  width: 20%;
  text-align: center;
}
.text {
  font-size: 12px;
  font-weight: 500;
  color: #A5B7CC;
}
.text img {
  vertical-align: middle;
  width: 14px;
  height: 14px;
  margin-bottom: 2px;
  margin-right: 2px;
  margin-left: 2px;
}
</style>
<style>
/* 搜索框 */
.alarm_search .contInput .el-input__inner{
  width: 329px;
  background: rgba(136,172,252,0.16);
  border: none;
  color: #6F7E90;
  border-radius: 20px 0px 0px 20px;
}
.alarm_search .el-button--default {
  width: 57px;
  height: 35px;
  line-height: 35px;
  background: rgba(136,172,252,0.16);
  color: #FFAD42;
  border-radius: 0px 30px 30px 0px;
  padding: 0;
  border: none;
  position: absolute;
  right: 0;
}
</style>
