<template>
  <!-- 处理记录 -->
  <div class="alarm_box">
    <div class="flexCB" >
      <div>
        <el-input  placeholder="请输入搜索内容" class="contInput" v-model="dealname"></el-input>
        <el-select v-model="dealtype" placeholder="处理类型" class="utF setSe">
          <el-option
						v-for="item in dealtypeOption"
						:key="item.value"
						:label="item.label"
						:value="item.value">
					</el-option>
        </el-select>
        <el-date-picker
          v-model="date"
          type="daterange"
          class="utF setSe"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
        <el-button class="searchbtn" @click="handleSearch">搜索</el-button>
        <el-button class="resetbtn" @click="handleReset">重置</el-button>
      </div>
      <div><el-button class="exportbtn" @click="exportHand">导出</el-button></div>
    </div>
    <div class="alarm_table hidTable">
      <el-table
        :data="dealList"
        style="width: 100%">
        <el-table-column
          label="序号"
          type="index">
        </el-table-column>
        <el-table-column
          label="告警编号"
          prop="ectpid">
          <!-- <template slot-scope="scope">
            <span style="color:#42EEFF;cursor: pointer;" @click="godispDetail(scope.row)" >{{scope.row.ectpid}}</span>
          </template> -->
        </el-table-column>
        <el-table-column
          label="告警时间"
          prop="ectptime">
        </el-table-column>
        <el-table-column
          label="设备名称"
          prop="equname">
        </el-table-column>
        <el-table-column
          label="处理时间"
          prop="dealtime">
        </el-table-column>
        <el-table-column
          label="处理备注"
          prop="dealmark"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          label="处理附件"
          prop="dealfile"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="color:#42EEFF;cursor: pointer;" @click="showFile(scope.row)" >{{scope.row.dealfile}}</span>
          </template>
           <!-- <template slot-scope="scope">
            <el-popover placement="right" title trigger="hover">
              <img :src="scope.row.dealfile" />
              <img
                slot="reference"
                :src="scope.row.dealfile"
                :alt="scope.row.dealfile"
                style="max-height: 20px;max-width: 130px"
              />
            </el-popover>
          </template> -->
        </el-table-column>
        <el-table-column
          label="接收人"
          prop="username">
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="PageChange"
      :current-page="page"
      :page-sizes="[10]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="indexPage">
    </el-pagination>

    <!-- 文件预览 -->
    <el-dialog title="文件预览" :visible.sync="fileShow" width="600px" center class="fireDialog hdBg hdTb">
      <img :src="filesrc" style="width: 100%;height: 100%;" />
    </el-dialog>
  </div>
</template>

<script>
import { getdealInfo, dealExport } from '@/http/api/alarm'

export default {
  props: ['unitId'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      dealList: [],
      date: null,
      dealname: '',
      dealtype: '',
      dealtypeOption: [
        { label: '全部', value: '0' },
        { label: '处理', value: '1' },
        { label: '误报', value: '2' }
      ],
      fileShow: false,
      filesrc: ''
    }
  },
  watch: {
    unitId (val) {
      this.companyid = val
      this.init()
    }
  },
  created () {
    this.roleid = JSON.parse(window.localStorage.getItem('loginInfo')).roleid
    if (this.roleid === '12') {
      this.companyid = this.unitId
    } else {
      this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    }
    this.init()
  },
  methods: {
    init () {
      let stime = ''
      let etime = ''
      if (this.date != null) {
        stime = this.date[0]
        etime = this.date[1]
      }
      const params = {
        companyid: this.companyid,
        page_index: this.page,
        page_size: this.size,
        dealname: this.dealname,
        dealstime: stime,
        dealetime: etime,
        dealtype: this.dealtype
      }
      getdealInfo(params).then(res => {
        if (res.status === '1') {
          this.dealList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.dealList = []
          this.total = 0
        }
      })
    },
    showFile (row) {
      this.fileShow = true
      this.filesrc = row.dealfile
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.page = 1
      this.init()
    },
    handleReset () {
      this.page = 1
      this.dealname = ''
      this.dealtype = ''
      this.date = null
      this.init()
    },
    // 导出
    exportHand () {
      let staTime = ''
      let endTime = ''
      if (this.date != null) {
        staTime = this.date[0]
        endTime = this.date[1]
      }
      const params = {
        companyid: this.companyid,
        dealname: this.dealname,
        dealstime: staTime,
        dealetime: endTime,
        dealtype: this.dealtype
      }
      dealExport(params).then(res => {
        if (res.status === '1') {
          this.$message.success(res.message)
          window.open(res.data.filepath)
        } else {
          this.$message.success(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>
.utF {
  margin: 0 16px;
}
.alarm_box  >>> .el-range-editor.el-input__inner {
  padding: 0 10px;
}
.alarm_box  >>>.el-range-editor .el-range-input {
  color: #A5B7CC;
}
</style>
