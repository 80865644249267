<template>
  <div class="alarm_box">
   <div class="company_equip">
      <div class="eq_title">
        <div class="er_item1" @click="tabClick(1)" :class="{'selected': tabId == 1}">告警信息</div>
        <div class="er_item2" @click="tabClick(2)" :class="{'selected': tabId == 2}">处理记录</div>
        <div class="er_item3" @click="tabClick(3)" :class="{'selected': tabId == 3}">分派记录</div>
        <div class="er_item4" @click="tabClick(4)" :class="{'selected': tabId == 4}">督办记录</div>
      </div>
      <div>
        <WarnList v-if="tabId == 1"  :unitId="unitId"></WarnList>
        <DealList v-if="tabId == 2" :unitId="unitId"></DealList>
        <DispList v-if="tabId == 3" :unitId="unitId"></DispList>
        <OverseList v-if="tabId == 4" :unitId="unitId"></OverseList>
      </div>
    </div>
  </div>
</template>

<script>
import WarnList from '@/components/work/alarmDetail/warnList.vue'
import DealList from '@/components/work/alarmDetail/dealList.vue'
import DispList from '@/components/work/alarmDetail/dispList.vue'
import OverseList from '@/components/work/alarmDetail/overseList.vue'

export default {
  name: 'Alarminfo',
  props: ['unitId'],
  components: {
    WarnList,
    DealList,
    DispList,
    OverseList
  },
  data () {
    return {
      tabId: 1,
    }
  },
  watch: {
    unitId (val) {
      this.companyid = val
      // this.init()
    }
  },
  created () {
    this.roleid = JSON.parse(window.localStorage.getItem('loginInfo')).roleid
    if (this.roleid === '12') {
      this.companyid = this.unitId
    } else {
      this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    }
  },
  methods: {
    tabClick(type) {
      this.tabId = type
    },
  }
}
</script>

<style scoped>
.alarm_box {
  width: 100%;
  height: 100%;
}
.company_equip {
  width: 100%;
  height: auto;
  position: relative;
}
.eq_title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0px 20px;
}
.er_item1 {
  padding: 0 30px;
  height: 35px;
  line-height: 35px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  background: rgba(136,172,252,0.16);
  border-radius: 20px 0px 0px 20px;
}
.er_item2, .er_item3 {
  padding: 0 30px;
  height: 35px;
  line-height: 35px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  background: rgba(136,172,252,0.16);
}
.er_item2 {
  margin: 0 2px;
}
.er_item3 {
  margin-right: 2px;
}
.er_item4 {
  padding: 0 30px;
  height: 35px;
  line-height: 35px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  background: rgba(136,172,252,0.16);
  border-radius: 0px 20px 20px 0px;
}
.selected {
  color: #FFAD42;
}
</style>
