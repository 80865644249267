<template>
<!-- 设备信息 -->
  <div class="equ_content">
    <div class="equip_list" v-for="(item,index) in companyEquip" :key="index">
        <div class="list_item" :class="item.allequcfonf > 0 ?'online':''"
          @click="item.allequcfonf > 0 && seeEquList(item)">
            <el-row>
              <el-col :span="10">
                <p><img :src="item.equtypesvg" /></p>
              </el-col>
              <el-col :span="12">
                <p>{{item.equtypename}}</p>
                <p class="item_desc">智能检测，火灾预警</p>
              </el-col>
            </el-row>
            <div class="num_row">
              <p class="num_start n1" :class="item.allequcfonf > 0 ?'num1':''">告警总数　{{item.alarmcount}}</p>
              <p class="num_start n3" :class="item.allequcfonf > 0 ?'num3':''">设备总数　{{item.allequcfonf}}</p>
            </div>
        </div>
    </div>
    <div v-if="companyEquip.length == 0" style="width:100%;text-align: center;">
        <img src="../../assets/img/notdata.png" style="width: 250px;"/>
        <div style="color:#fff;">暂无设备</div>
    </div>
  </div>
</template>

<script>
import { getEquipmentType } from '@/http/api/work'

export default {
  name: 'Eqinfo',
  props: ['unitId'],
  data () {
    return {
      companyEquip: [],
      imgOk: null,
      imgNo: null
    }
  },
  watch: {
    unitId (val) {
      this.companyid = val
      this.init()
    }
  },
  created () {
    this.roleid = JSON.parse(window.localStorage.getItem('loginInfo')).roleid
    if (this.roleid === '12') {
      this.companyid = this.unitId
    } else {
      this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    }
    this.init()
  },
  methods: {
    init () {
      const params = {
        companyid: this.companyid
      }
      getEquipmentType(params).then(res => {
        if (res.status === '1') {
          this.companyEquip = res.data
        } else {
          this.companyEquip = []
        }
      })
    },
    seeEquList (row) {
      // if (row.equtypeid == '1') {
      //   this.$emit('HlsVideo',row)
      // } else if (row.equtypeid == '2') {
      //   this.$emit('EzuVideo',row)
      // } else {
      //   this.$emit('lookEquList',row)
      // }
      this.$emit('lookEquList', row)
    }
  }
}
</script>

<style scoped>
.equ_content {
  width: 100%;
  height: 100%;
  /*margin-top: 10px;*/
  display: flex;
  flex-wrap: wrap;
}
.equip_list {
  width: 25%;
  height: auto;
  position: relative;
  /* border: 1px solid #fff; */
  box-sizing: border-box;
}
.list_item {
  width: 90%;
  color: #666666;
  font-size: 14px;
  border: 1px solid #6F7E90;
  background: rgba(6, 26, 52, 0.5);
  padding: 10px;
  box-sizing: border-box;
  /*margin-top: 10px;*/
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 25px;
  line-height: 25px;
  /* margin: 10px auto 25px; */
}
.list_item img {
  margin-left: 20px;
}
.online {
  color: #42EFFF;
  border: 1px solid #00D4FF;
}
.item_desc {
  font-size: 14px;
  margin-top: 10px;
}
.num_start {
  border-right: 1px solid rgba(165, 183, 204, 0.3);
  text-align: center;
  /*margin-right: 20px;*/
}
.num_row {
  display: flex;
  justify-content: center;
}
.n1 {
  width: 50%;
  /* padding-right: 20px; */
  box-sizing: border-box;
}
.n3 {
  width: 50%;
  /* padding-left: 20px; */
  box-sizing: border-box;
}
.num_start:last-of-type {
  border-right: none;
}
.num1 {
  color: #F13737;
  border-right: 1px solid #00BAE0;
}
.num3 {
  color: #FFAD42;
}
</style>
