import { get, post, put, deletefn } from '@/http/http'

// 告警处理
export const alarmDeal = p => post('v1/alarminfo/alarmdeal', p)

// 误报处理
export const alarmReport = p => post('v1/alarminfo/mistakedeal', p)

// 告警分派
export const alarmAssign = p => post('v1/alarminfo/alarmdispatch', p)

// 告警督办
export const alarmSuper = p => post('v1/alarminfo/alarmoversee', p)

// 告警信息查询
export const getalarmInfo = p => get('v1/alarmdealinfo/getalarminfo', p)

// 处理信息
export const getdealInfo = p => get('v1/alarmdealinfo/getdealinfo', p)

// 分派信息
export const getdispatchInfo = p => get('v1/alarmdealinfo/getdispatchinfo', p)

// 督办信息
export const getoverseeInfo = p => get('v1/alarmdealinfo/getoverseeinfo', p)

// 告警导出
export const getEquipExport = p => post('v1/exportfile/export_alarminfo', p)

// 处理导出
export const dealExport = p => post('v1/exportfile/export_dealinfo', p)

// 分派导出
export const assignExport = p => post('v1/exportfile/export_dispatchinfo', p)

// 分派导出
export const superExport = p => post('v1/exportfile/export_overseeinfo', p)
