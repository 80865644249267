<template>
<!-- 消防预案 -->
  <div class="alarm_box">
    <div class="plan_cont_box">
      <div class="flexAC" >
        <el-input  placeholder="请输入搜索内容" class="contInput" v-model="planname"></el-input>
        <el-button class="searchbtn"  @click="handleSearch">搜索</el-button>
        <el-button class="resetbtn" @click="handleReset">重置</el-button>
      </div>
      <div class="plan_table hidTable">
        <el-table
          stripe
          :data="planList"
          style="width: 100%">
          <el-table-column
            label="序号"
            type="index">
          </el-table-column>
          <el-table-column
            label="单位名称"
            prop="companyname"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            label="预案标题"
            prop="doc_title"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            label="预案简介"
            prop="doc_content"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            label="执行时间"
            prop="doc_time"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            label="上传时间"
            prop="creatime">
          </el-table-column>
          <el-table-column
            label="是否驳回"
            prop="rejected">
            <template slot-scope="scope">
              <span v-if="scope.row.rejected == true" >是</span>
              <span v-if="scope.row.rejected == false" >否</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="handleDetail(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @current-change="PageChange"
        :current-page="page"
        :page-sizes="[10]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        class="indexPage">
      </el-pagination>
    </div>
    <!-- 详情 -->
    <el-dialog title="预案详情" :visible.sync="detailShow" width="800px" center class="fireDialog hdBg hdTb">
      <div class="flexSC">
        <div class="ud_left">
          <div class="rig_title"><i class="el-icon-caret-right" style="margin:0 10px;"></i><p>预案基本信息</p></div>
          <p class="ud_c">单位名称：{{ detailInfo.companyname }}</p>
          <p class="ud_c">预案标题：{{ detailInfo.doc_title }}</p>
          <p class="ud_c">预案简介：{{ detailInfo.doc_content }}</p>
          <div class="flexCB">
            <p class="ud_c">执行时间：{{ detailInfo.doc_time }}</p>
            <p class="ud_c">上传时间：{{ detailInfo.creatime }}</p>
          </div>
        </div>
      </div>

      <div >
        <div class="ud_left">
          <div class="rig_title"><i class="el-icon-caret-right" style="margin:0 10px;"></i><p>预案附件</p></div>
          <div v-for="(item, index) in detailInfo.doc_fire" :key="index">
            <div class="ud_doc"><i class="el-icon-paperclip" style="margin-right: 10px;"></i>{{ item.name }}<span class="down_t" @click="downDoc(item.name,item.value)">下载</span></div>
          </div>
        </div>
      </div>

      <div class="flexSC" v-if="detailInfo.rejected == true">
        <div class="ud_left">
          <div class="rig_title"><i class="el-icon-caret-right" style="margin:0 10px;"></i><p>驳回原因</p></div>
          <p class="ud_c">{{ detailInfo.rej_mark }}</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getplanInfo } from '@/http/api/plan'
import { constants } from 'os';

export default {
  name: 'Fireplan',
  props: ['unitId'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      planList: [],
      planname: '',
      detailShow: false,
      detailInfo: {}
    }
  },
  watch: {
    unitId (val) {
      this.companyid = val
      this.init()
    }
  },
  created () {
    this.roleid = JSON.parse(window.localStorage.getItem('loginInfo')).roleid
    if (this.roleid === '12') {
      this.companyid = this.unitId
    } else {
      this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    }
    this.init()
  },
  methods: {
    init () {
      const params = {
        page_index: this.page,
        page_size: this.size,
        companyid: this.companyid,
        planname: this.planname,
        rejected: this.rejected
      }
      getplanInfo(params).then(res => {
        if (res.status === '1') {
          this.planList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.planList = []
          this.total = 0
        }
      })
    },
    downDoc (name, path) {
      window.open( path, '_blank')
    },
    PageChange (page) {
      this.page = page
    },
    handleDetail (row) {
      this.detailShow = true
      this.detailInfo = row
    },
    handleSearch () {
      this.page = 1
      this.init()
    },
    handleReset () {
      this.page = 1
      this.planname = ''
      this.init()
    }
  }
}
</script>

<style scoped>
.rig_title {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  background: linear-gradient(91deg, rgba(33, 110, 243, 0.5) 0%, rgba(36, 104, 240, 0) 100%);
  margin-bottom: 10px;
}
.plan_cont_box {
  width: 100%;
}
.plan_table {
  margin-top: 20px;
}
.udi_k {
  width: 4px;
  height: 18px;
  background: #196DF7;
  opacity: 1;
}
.ud_t {
  font-size: 18px;
  font-family: 'PingFang SC';
  font-weight: 700;
  line-height: 25px;
  color: #FFFFFF;
  opacity: 1;
  margin-left: 10px;
}
.ud_c {
  font-size: 16px;
  font-family: 'PingFang SC';
  /* font-weight: bold; */
  line-height: 25px;
  color: #FFFFFF;
  opacity: 1;
  margin-bottom: 10px;
}
.down_t {
  font-size: 14px;
  font-family: 'PingFangSC-Regular';
  line-height: 22px;
  color: #42EEFF;
  opacity: 1;
  margin-left: 20px;
  cursor: pointer;
}
.plan_cont_box >>> .contInput .el-input__inner{
  background: rgba(136,172,252,0.16);
  border: none;
  color: #A5B7CC;
}
.fireDialog >>> .el-dialog{
  background: #061A34;
  color: #fff;
  border: 1px solid #42EEFF;
  border-radius: 8px;
}
.fireDialog >>> .el-dialog__title {
  color: #fff;
}
.fireDialog >>> .ud_doc {
  color: #A5B7CC;
}
.plan_table >>> .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: transparent;
}
</style>
