<template>
  <!-- 督办记录 -->
  <div class="alarm_box">
    <div class="flexCB" >
      <div>
        <el-input  placeholder="请输入搜索内容" class="contInput" v-model="ovsname"></el-input>
        <el-date-picker
          v-model="date"
          type="daterange"
          class="utF setSe"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
        <el-button class="searchbtn" @click="handleSearch">搜索</el-button>
        <el-button class="resetbtn" @click="handleReset">重置</el-button>
      </div>
      <div><el-button class="exportbtn" @click="exportHand">导出</el-button></div>
    </div>
    <div class="alarm_table hidTable">
      <el-table
        :data="overseList"
        style="width: 100%">
        <el-table-column
          label="序号"
          type="index">
        </el-table-column>
        <el-table-column
          label="告警编号"
          prop="ectpid">
          <!-- <template slot-scope="scope">
            <span style="color:#42EEFF;cursor: pointer;" @click="gooverseDetail(scope.row)" >{{scope.row.ectpid}}</span>
          </template> -->
        </el-table-column>
        <el-table-column
          label="督办人"
          prop="ovsusername">
        </el-table-column>
        <el-table-column
          label="督办时间"
          prop="ovstime">
        </el-table-column>
        <el-table-column
          label="督办方式"
          prop="ovsway">
          <template slot-scope="scope">
					  <span v-if="scope.row.ovsway == 1">平台通知</span>
					  <span v-if="scope.row.ovsway == 2">短信通知</span>
					  <span v-if="scope.row.ovsway == 3">语音通知</span>
					</template>
        </el-table-column>
        <el-table-column
          label="督办说明"
          prop="ovsmark"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          label="接收人"
          prop="username">
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="PageChange"
      :current-page="page"
      :page-sizes="[10]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="indexPage">
    </el-pagination>
  </div>
</template>

<script>
import { getoverseeInfo, superExport } from '@/http/api/alarm'

export default {
  props: ['unitId'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      overseList: [],
      date: null,
      ovsname: '',
    }
  },
  watch: {
    unitId (val) {
      this.companyid = val
      this.init()
    }
  },
  created () {
    this.roleid = JSON.parse(window.localStorage.getItem('loginInfo')).roleid
    if (this.roleid === '12') {
      this.companyid = this.unitId
    } else {
      this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    }
    this.init()
  },
  methods: {
    init () {
      let stime = ''
      let etime = ''
      if (this.date != null) {
        stime = this.date[0]
        etime = this.date[1]
      }
      const params = {
        companyid: this.companyid,
        page_index: this.page,
        page_size: this.size,
        ovsname: this.ovsname,
        ovsstime: stime,
        ovsetime: etime
      }
      getoverseeInfo(params).then(res => {
        if (res.status === '1') {
          this.overseList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.overseList = []
          this.total = 0
        }
      })
    },
    // 督办处理
    gooverseDetail () {

    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.page = 1
      this.init()
    },
    handleReset () {
      this.page = 1
      this.ovsname = ''
      this.date = null
      this.init()
    },
    // 导出
    exportHand () {
      let staTime = ''
      let endTime = ''
      if (this.date != null) {
        staTime = this.date[0]
        endTime = this.date[1]
      }
      const params = {
        companyid: this.companyid,
        ovsname: this.ovsname,
        ovsstime: staTime,
        ovsetime: endTime
      }
      superExport(params).then(res => {
        if (res.status === '1') {
          this.$message.success(res.message)
          window.open(res.data.filepath)
        } else {
          this.$message.success(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>
.utF {
  margin: 0 16px;
}
.alarm_box  >>> .el-range-editor.el-input__inner {
  padding: 0 10px;
}
.alarm_box  >>>.el-range-editor .el-range-input {
  color: #A5B7CC;
}
</style>
