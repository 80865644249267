<template>
  <div>
    <div class="fire_container" v-if="companyList.length != 0">
      <div class="pro_left">
        <div class="left_cont">
          <ul class="company"  ref="tree">
            <li class="company_li" v-for="(item,index) in companyList"
            :key="index" :class="item.firestaid == selectId?'company_active':''"
            @click="selectCompany(item,index)">
              <span class="iconfont">&#xe62f;</span>
              {{item.firestaname}}
            </li>
          </ul>
        </div>
      </div>
      <div class="pro_middle"></div>
      <div class="pro_right" >
        <div class="flexSC">
          <div class="ud_left">
            <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
              <div class="udi_k"></div>
              <p class="ud_t">微消站基本信息</p>
            </div>
            <p class="ud_c">单位名称：{{ detailInfo.companyname }}</p>
            <p class="ud_c">微消站名称：{{ detailInfo.firestaname }}</p>
            <p class="ud_c">微消站地址：{{ detailInfo.firestaress }}</p>
            <div class="flexCB">
              <p class="ud_c">联系人：{{ detailInfo.firestauser }}</p>
              <p class="ud_c">联系电话：{{ detailInfo.firestaphone }}</p>
            </div>
          </div>
        </div>

        <div class="flexSC">
          <div class="ud_left">
            <div style="display: flex;justify-content: flex-start;align-items: center;margin: 50px 0 20px 0;">
              <div class="udi_k"></div>
              <p class="ud_t">消防设备信息</p>
            </div>
            <div class="udi_c" v-for="(item, index) in detailInfo.firestaequ" :key="index">
              <div class="udi_r"><span>{{item.label}}</span> : <span>{{item.value}}</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="companyList.length == 0" style="width:100%;height:100%;text-align:center;">
      <img src="../../assets/img/notdata.png" style="width:150px"/>
      <p>暂无数据</p>
    </div>
  </div>
</template>

<script>
import { getfirelist } from '@/http/api/fire'

export default {
  name: 'Firestation',
  props: ['unitId'],
  data () {
    return {
      select: null,
      companyList: [],
      unit_id: null,
      detailInfo: {},
      selectId: '',
    }
  },
  watch: {
    unitId (val) {
      this.companyid = val
      this.init()
    }
  },
  created () {
    this.roleid = JSON.parse(window.localStorage.getItem('loginInfo')).roleid
    if (this.roleid === '12') {
      this.companyid = this.unitId
    } else {
      this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    }
    this.init()
  },
  methods: {
    init () {
      const params = {
        companyid: this.companyid
      }
      getfirelist(params).then(res => {
        if (res.status === '1') {
          if (res.data.data.length != 0) {
            this.companyList = res.data.data
            this.detailInfo = res.data.data[0]
            this.selectId = res.data.data[0].firestaid
          } else {
            this.companyList = []
            this.detailInfo = ''
            this.selectId = ''
          }
        } else {
          this.companyList = []
        }
      })
    },
    selectCompany(item, index){
      this.selectId = item.firestaid
      this.detailInfo = item
    },
  }
}
</script>

<style scoped>
.fire_container {
  width: 100%;
  height: 100%;
  padding: 0px 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.pro_left,.pro_right{
  width: 18%;
  height: 100%;
  /* background: rgba(136,172,252,0.16); */
  box-sizing: border-box;
}
.pro_right{
  width: 81%;
  padding: 0 30px;
}
.left_cont {
  width: 100%;
  height: 93%;
  overflow: hidden;
}
.pro_middle {
  width: 1px;
  /* height: 549px; */
  background: #6F7E90;
  opacity: 0.3;
  border-radius: 20px;
  margin:0 20px;
}
.company {
  width: 100%;
  height: 94%;
  margin: 0;
  padding: 0;
  /* color: #888888; */
  overflow-y: auto;
  /* scrollbar-width: none; */
}
.company::-webkit-scrollbar,.right_cont::-webkit-scrollbar {
  display: none;
}
.company_li{
  cursor: pointer;
  width: 220px;
  height: 50px;
  box-sizing: border-box;
  font-size: 16px;
  background: rgba(136,172,252,0.16);
  color: #6F7E90;
  text-align: center;
  line-height: 50px;
  margin-bottom: 10px;
}
.company_active{
  background: rgba(136,172,252,0.16);
  color: #FFAD42;
}
.udi_k {
  width: 4px;
  height: 18px;
  background: #196DF7;
  opacity: 1;
}
.ud_t {
  font-size: 18px;
  font-family: 'PingFang SC';
  font-weight: 700;
  line-height: 25px;
  color: #FFFFFF;
  opacity: 1;
  margin-left: 10px;
}
.ud_c {
  font-size: 16px;
  font-family: 'PingFang SC';
  /* font-weight: bold; */
  line-height: 23px;
  color: #FFFFFF;
  opacity: 1;
  margin-bottom: 10px;
}
.udi_c {
  display: flex;
  width: 160px;
  height: 40px;
  margin-left: 10px;
  margin-bottom: 10px;
  float: left;
  font-size: 16px;
  font-family: 'PingFang SC';
  /* font-weight: bold; */
  line-height: 23px;
  color: #FFFFFF;
}
</style>
