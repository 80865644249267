<template>
  <div class="supervise_cont">
    <el-form :model="form" :rules="formRules" ref="form" class="alarmHForm">
      <el-form-item label="督办方式" class="afItem" prop="ovsway">
        <div class="alarmRadio afInput" style="width: 500px;display:flex;padding-top: 4px;">
          <el-radio v-model="form.ovsway" label="1">平台通知</el-radio>
          <el-radio v-model="form.ovsway" label="2">短信通知</el-radio>
          <el-radio v-model="form.ovsway" label="3">语音通知</el-radio>
        </div>
      </el-form-item>
      <el-form-item label="接收人" class="afItem" prop="dealuser">
        <el-select v-model="form.dealuser" placeholder="请选择接收人" class="afInput">
          <el-option
              v-for="item in userList"
              :key="item.userid"
              :label="item.username"
              :value="item.userid">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="督办说明" prop="ovsmark" class="afItem">
        <textarea v-model="form.ovsmark" class="OperTextareas" placeholder="请输入督办说明" maxlength="150"></textarea>
        <p class="OperText"><span>{{ form.ovsmark.length }}</span>/150</p>
      </el-form-item>
      <div class="flexCE">
        <div class="afCancel" @click="handCancel()">取消</div>
        <div class="afConfirm" @click="handConfirm()">确定</div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { userQuery } from '@/http/api/user'
import { nowTime } from '@/utils/validate'
import { alarmSuper } from '@/http/api/alarm'
export default {
  props: ['alarmOperate'],
  data () {
    return {
      companyid: '',
      userList: [],
      form: {
        ovsway: '1',
        dealuser: '',
        ovsmark: ''
      },
      formRules: {
        ovsway: [{ required: true, trigger: 'blur', message: '请选择分派方式' }],
        dealuser: [{ required: true, trigger: 'blur', message: '请选择接收人' }],
        ovsmark: [{ required: true, trigger: 'blur', message: '派单说明不能为空' }]
      }
    }
  },
  created () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    this.getUserList()
  },
  methods: {
    getUserList () {
      userQuery({ companyid: this.companyid }).then(res => {
        if (res.status === '1') {
          this.userList = res.data.data
        } else {
          this.userList = []
        }
      })
    },
    handCancel () {
      this.$refs.form.resetFields()
      this.$emit('superHandCancel')
    },
    handConfirm () {
      this.$refs.form.validate((valid) => {
        if (!valid) return
        const params = {
          ectpid: this.alarmOperate.ectpid,
          dealuser: this.form.dealuser,
          ovstime: nowTime(),
          ovsmark: this.form.ovsmark,
          ovsway: this.form.ovsway
        }
        alarmSuper(params).then(res => {
          if (res.status === '1') {
            this.$emit('superHandConfirm')
            this.$message.success(res.message)
            this.$refs.form.resetFields()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    }
  }

}
</script>

<style scoped>

</style>
