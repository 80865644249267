<template>
  <!-- 报表统计 -->
  <div>
    <div class="ifTab">
      <div class="er_item1" @click="tabClick(1)" :class="{'selected': tabId == 1}">周报</div>
      <div class="er_item2" @click="tabClick(2)" :class="{'selected': tabId == 2}">月报</div>
    </div>
    <div class="ifCont">
      <!-- 周报 -->
      <div class="week" v-if="tabId == 1">
        <div class="ifSearch alarm_box">
          <el-input  placeholder="请输入搜索内容" class="contInput" v-model="wordKey"></el-input>
          <el-button class="searchbtn" @click="handleSearch">搜索</el-button>
          <el-button class="resetbtn" @click="handleReset">重置</el-button>
        </div>
        <div class="hidTable">
          <el-table
              :data="weekList"
              style="width: 100%">
            <el-table-column
                label="序号"
                type="index">
            </el-table-column>
            <el-table-column
                label="报表名称"
                prop="repname"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="生成时间"
              prop="reptime"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                width="150"
                label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="handleDown(scope.row)">下载</el-button>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
              @current-change="PageChange"
              :current-page="page"
              :page-sizes="[10]"
              :page-size="size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              class="indexPage">
          </el-pagination>
        </div>
      </div>
      <!-- 月报 -->
      <div class="week" v-if="tabId == 2">
        <div class="ifSearch alarm_box">
          <el-input  placeholder="请输入搜索内容" class="contInput" v-model="wordKey"></el-input>
          <el-button class="searchbtn" @click="handleSearch">搜索</el-button>
          <el-button class="resetbtn" @click="handleReset">重置</el-button>
        </div>
        <div class="hidTable">
          <el-table
              :data="weekList"
              style="width: 100%">
            <el-table-column
                label="序号"
                type="index">
            </el-table-column>
            <el-table-column
                label="报表名称"
                prop="repname"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                label="生成时间"
                prop="reptime"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                width="150"
                label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="handleDown(scope.row)">下载</el-button>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
              @current-change="PageChange"
              :current-page="page"
              :page-sizes="[10]"
              :page-size="size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              class="indexPage">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reportForm } from '@/http/api/unit'
export default {
  props: ['unitId'],
  data () {
    return {
      tabId: 1,
      wordKey: '',
      page: 1,
      size: 10,
      total: 0,
      weekList: [],
      companyid: ''
    }
  },
  watch: {
    unitId (val) {
      this.companyid = val
      this.init()
    }
  },
  created () {
    this.roleid = JSON.parse(window.localStorage.getItem('loginInfo')).roleid
    if (this.roleid === '12') {
      this.companyid = this.unitId
    } else {
      this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    }
    this.init()
  },
  methods: {
    init () {
      const params = {
        page_index: this.page,
        page_size: this.size,
        companyid: this.companyid,
        repname: this.wordKey,
        reptype: this.tabId
      }
      reportForm(params).then(res => {
        if (res.status === '1') {
          console.log(res)
          this.weekList = res.data.data
          this.total = res.data.recordcount
          console.log(this.weekList)
        }
      })
    },
    tabClick (type) {
      this.page = 1
      this.tabId = type
      this.init()
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    /* 下载 */
    handleDown (row) {
      window.open(row.reppath, '_blank')
    },
    /* 搜索 */
    handleSearch () {
      this.page = 1
      this.init()
    },
    /* 重置 */
    handleReset () {
      this.page = 1
      this.wordKey = ''
      this.init()
    }
  }
}
</script>

<style scoped>
.ifTab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0px 20px;
}
.er_item1,.er_item2 {
  padding: 0 30px;
  height: 35px;
  line-height: 35px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  background: rgba(136,172,252,0.16);
  border-radius: 20px 0px 0px 20px;
}
.er_item2 {
  margin: 0 2px;
  border-radius: 0px 20px 20px 0px;
}
.selected {
  color: #FFAD42;
}
.contInput >>> .el-input__inner{
  background: rgba(136,172,252,0.16);
  border: none;
  color: #A5B7CC;
}
</style>
