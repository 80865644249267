<template>
  <div class="loading_box">
    <div class='loading-anim'>
      <div class='border' :class="isResult == 1? 'in':''"></div>
    </div>

    <div class="border_box">
      <div class="circle_box">
        <div class="loadEffect " :class="isResult == 1? 'turn':''">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="load_bg">{{ timing }}</div>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: 'ScoreLoading',
  props: ['isResult', 'companyScore'],
  data () {
    return {
      percentage: 1,
      timing: null
    }
  },
  watch: {
    isResult: function (a, b) {
      if (a === 1) {
        this.init()
      }
    },
    companyScore (val) {
      this.timing = val
    }
  },
  created () {
    this.timing = this.companyScore
    // console.log(this.isResult)
    // console.log(this.companyScore)
  },
  methods: {
    init () {
      var that = this
      that.percentage = 1
      var timer = setInterval(function () {
        that.percentage ++
        that.timing = that.percentage
        if (that.percentage > that.companyScore) {
          clearInterval(timer)
          that.timing = that.companyScore
          that.isResult = 1
          that.$emit('clear')
        }
      }, 30)
    }
  }
}

</script>

<style>
.loading_box{
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 50%;
}
.loading-anim {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: all .2s ease-out;
}
.border_box{
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  border-radius: 50%;
  border: 4px solid #01afcf;
  padding: 5px;
  box-sizing: border-box;
}
.border {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
  border: 2px solid #076070;
  box-sizing: border-box;
}
.in {
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: spin 1s linear reverse infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.circle_box{
  width: 100%;
  height: 100%;
  /* border: 1px solid #fff; */
  /* border-radius: 50%; */
}
.loadEffect {
  width: 100%;
  height: 100%;
  position: relative;
}
.loadEffect span{
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #0ebcde;
  position: absolute;

}
.turn span{
  animation: load 1s ease infinite;
  -webkit-animation: load 1s ease infinite;
}
@keyframes  load{
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0.2;
  }
}
@-webkit-keyframes load{
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0.2;
  }
}
.loadEffect span:nth-child(1){
  left: 0;
  top: 50%;
  margin-top:-3px;
  animation-delay: 0.13s;
  -webkit-animation-delay:0.13s;
}
.loadEffect span:nth-child(2){
  left: 9.5px;
  top: 11.5px;
  animation-delay: 0.26s;
  -webkit-animation-delay:0.26s;
}
.loadEffect span:nth-child(3){
  left: 50%;
  top: 0;
  margin-left: -3px;
  animation-delay: 0.39s;
  -webkit-animation-delay:0.39s;
}
.loadEffect span:nth-child(4){
  top: 11.5px;
  right:9.5px;
  animation-delay: 0.52s;
  -webkit-animation-delay:0.52s;
}
.loadEffect span:nth-child(5){
  right: 0;
  top: 50%;
  margin-top:-3px;
  animation-delay: 0.65s;
  -webkit-animation-delay:0.65s;
}
.loadEffect span:nth-child(6){
  right: 9.5px;
  bottom:11.5px;
  animation-delay: 0.7s;
  -webkit-animation-delay:0.78s;
}
.loadEffect span:nth-child(7){
  bottom: 0;
  left: 50%;
  margin-left: -3px;
  animation-delay: 0.91s;
  -webkit-animation-delay:0.91s;
}
.loadEffect span:nth-child(8){
  bottom: 11.5px;
  left: 9.5px;
  animation-delay: 1.04s;
  -webkit-animation-delay:1.04s;
}
.load_bg{
  background: rgba(66, 239, 255, 0.4);
  width: 70%;
  height: 70%;
  position: absolute;
  border-radius: 50%;
  top: 15%;
  left: 15%;
  text-align: center;
  line-height: 65px;
  font-size: 25px;
  font-weight: 600;
  color: #10e8dd;
}
.you{
  color: #12CC2D;
}
.liang{
  color: #D39B16;
}
.cha{
  color: #C50909;
}
</style>
