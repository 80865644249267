import { get, post, put, deletefn } from '@/http/http'

// 微消防查询
export const getfirelist = p => get('v1/standing/getstanding', p)

// 微消站设备查询
export const getfireinfo = p => get('v1/standing/getstaequinfo', p)

// 微消站添加
export const fireAdd = p => post('v1/standing/addstanding', p)

// 微消站修改
export const fireEdit = p => put('v1/standing/updatestanding', p)

// 微消站删除
export const fireDelete = p => deletefn('v1/standing/deletestanding', p)