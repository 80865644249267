<template>
  <!-- 巡检信息 -->
  <div>
    <div class="alarm_box">
      <div class="flexCB" >
        <div>
          <el-input  placeholder="请输入搜索内容" class="contInput" v-model="inplanname"></el-input>
          <el-select v-model="inplanstate" placeholder="全部状态" class="utF setSe">
            <el-option
              v-for="item in inplanstateOption"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <!-- <el-date-picker
            v-model="date"
            type="daterange"
            class="utF setSe"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker> -->
          <el-button class="searchbtn" @click="handleSearch">搜索</el-button>
          <el-button class="resetbtn" @click="handleReset">重置</el-button>
        </div>
        <div><el-button class="exportbtn" @click="handleExport">导出</el-button></div>
      </div>
      <div class="alarm_table hidTable">
        <el-table
          :data="planList"
          style="width: 100%">
          <el-table-column
            label="序号"
            type="index">
          </el-table-column>
          <el-table-column
            label="进度"
            prop="percentage">
            <template slot-scope="scope">
              <span :class="{ 'state1': scope.row.inplanstate ==='1','state2': scope.row.inplanstate ==='2','state3': scope.row.inplanstate ==='3', 'state4': scope.row.inplanstate ==='4','state5': scope.row.inplanstate ==='5'}">
                {{ scope.row.percentage }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="巡检计划名称"
            prop="inplanname"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            label="制定人"
            prop="username">
          </el-table-column>
          <el-table-column
            label="状态"
            prop="inplanstate">
            <template slot-scope="scope">
              <span :class="{ 'state1': scope.row.inplanstate ==='1','state2': scope.row.inplanstate ==='2','state3': scope.row.inplanstate ==='3', 'state4': scope.row.inplanstate ==='4','state5': scope.row.inplanstate ==='5'}">
                {{ scope.row.complete }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="循环周期"
            show-overflow-tooltip
            prop="inplanecycle,inplantype">
            <template slot-scope="scope">
              <span v-if="scope.row.inplantype == 1">{{scope.row.inplanecycle}} (星期)</span>
              <span v-if="scope.row.inplantype == 2">{{scope.row.inplanecycle}} (月)</span>
            </template>
          </el-table-column>
          <el-table-column
            label="巡检人员(人)"
            prop="usercount">
            <template slot-scope="scope">
              <span style="color:#42EEFF">{{scope.row.usercount}}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="巡检点(个)"
            prop="pointcount">
            <template slot-scope="scope">
              <span style="color:#42EEFF">{{scope.row.pointcount}}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="开始时间"
            prop="inplanstime">
          </el-table-column>
          <el-table-column
            label="结束时间"
            prop="inplanetime">
          </el-table-column>
          <el-table-column
            label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="handleDetail(scope.row)" style="color:#42EEFF;">详情</el-button>
              <el-button type="text" size="small" @click="handleDelete(scope.row)" style="color:#F5222D;">删除</el-button>
              <el-button  type="text" size="small" @click="handleStop(scope.row)" style="color:#42EEFF;" v-if="scope.row.inplanstate ==='1'">停用</el-button>
              <el-button  type="text" size="small" @click="handleStop(scope.row)" style="color:#42EEFF;" v-if="scope.row.inplanstate ==='2'">停用</el-button>
              <el-button  type="text" size="small" @click="handleStop(scope.row)" style="color:#42EEFF;" v-if="scope.row.inplanstate ==='3'">停用</el-button>
              <el-button  type="text" size="small" v-if="scope.row.inplanstate ==='4'"  disabled>停用</el-button>
              <el-button  type="text" size="small" v-if="scope.row.inplanstate ==='5'" disabled>停用</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @current-change="PageChange"
        :current-page="page"
        :page-sizes="[10]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        class="indexPage">
      </el-pagination>
    </div>

    <!-- 详情 -->
    <el-dialog title="巡检详情" :visible.sync="detailShow" width="900px" center class="gyDialog hdBg hdTb">
      <div class="plancont">
        <div class="plan_left">
          <div class="rig_title"><i class="el-icon-caret-right" style="margin:0 10px;"></i><p>巡检计划基本信息</p></div>
          <div >
            <p class="udiIP">巡检计划名称：<span style="color: #A5B7CC;">{{ detailInfo.inplanname }}</span></p>
            <p class="udiIP">状态：
              <span :class="{ 'sr1': detailInfo.inplanstate ==='1','sr2': detailInfo.inplanstate ==='2','sr3': detailInfo.inplanstate ==='3','sr4': detailInfo.inplanstate ==='4','sr5': detailInfo.inplanstate ==='5'}">{{ detailInfo.complete }}</span>
            </p>
            <p class="udiIP">制定人：<span style="color: #A5B7CC;">{{ detailInfo.username }}</span></p>
            <p class="udiIP">开始时间：<span style="color: #A5B7CC;">{{ detailInfo.inplanstime }}</span></p>
            <p class="udiIP">结束时间：<span style="color: #A5B7CC;">{{ detailInfo.inplanetime }}</span></p>
            <p class="udiIP" style="height: 60px;">循环周期：<span style="color: #A5B7CC;">{{ detailInfo.inplanecycle }}</span></p>
            <p class="udiIP">进度：<span :class="{ 'sr1': detailInfo.inplanstate ==='1','sr2': detailInfo.inplanstate ==='2','sr3': detailInfo.inplanstate ==='3','sr4': detailInfo.inplanstate ==='4','sr5': detailInfo.inplanstate ==='5'}">{{ detailInfo.percentage }}</span></p>
          </div>
        </div>
        <div style="width: 2px;height: 484px;background: #1B3255;opacity: 0.6;border-radius: 4px;"></div>
        <div class="plan_right">
          <div class="plan_user">
            <div class="rig_title"><i class="el-icon-caret-right" style="margin:0 10px;"></i><p>巡检人员</p></div>
            <div class="user_list">
              <p class="udi_s" v-for="(item, index) in inplanlist" :key="index">{{ item.username }}</p>
            </div>
          </div>

          <div class="plan_point">
            <div class="rig_title"><i class="el-icon-caret-right" style="margin:0 10px;"></i><p>巡检点</p></div>
            <div class="plan_info">
              <el-collapse  v-model="activeName" accordion class="botCollapse">
                <el-collapse-item name="1">
                  <template slot="title">
                    <i class="iconfont iconround btIco"></i>巡检点名称
                  </template>
                  <div class="btc_cont">
                    <p v-for="(item, index) in inplanpoint" :key="index"><span>{{ item.equname }}</span><span style="margin-left:10px;">({{ item.adderss }})</span></p>
                    <!-- <p>电梯火灾探测器<span>（未央区文景路中段一方中港国际6层605办公区6号工位）</span></p>
                    <p>视频联动<span>（二楼西南方向上方）</span></p> -->
                  </div>
                </el-collapse-item>
                <!-- <el-collapse-item name="2">
                  <template slot="title">
                    <i class="iconfont iconround btIco"></i>巡检点名称
                  </template>
                  <div class="btc_cont">
                    <p>智能烟感<span>（二楼西南方向上方）</span></p>
                    <p>电梯火灾探测器<span>（未央区文景路中段一方中港国际6层605办公区6号工位）</span></p>
                    <p>视频联动<span>（二楼西南方向上方）</span></p>
                  </div>
                </el-collapse-item> -->
              </el-collapse>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { getInspecplan, getplanDetails, deleteInspecplan, updatePlandisa, onSiteExport } from '@/http/api/onsite'
import { constants } from 'os';

export default {
  props: ['unitId'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      planList: [],
      date: null,
      inplanname: '',
      inplanstate: '',
      inplanstateOption: [
        {
          label: '待完成',
          value: '1'
        },
        {
          label: '进行中',
          value: '2'
        },
        {
          label: '已结束',
          value: '3'
				}
      ],
      detailShow: false,
      detailInfo: {},
      inplanlist: [],
      inplanpoint: [],
      activeName: '1'
    }
  },
  watch: {
    unitId (val) {
      this.companyid = val
      this.init()
    }
  },
  created () {
    if (this.roleid === '12') {
      this.companyid = this.unitId
    } else {
      this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    }
    this.init()
  },
  methods: {
    init () {
      let stime = ''
      let etime = ''
      if (this.date != null) {
        stime = this.date[0]
        etime = this.date[1]
      }
      const params = {
        inplanname: this.inplanname,
        page_index: this.page,
        page_size: this.size,
        inplanstime: stime,
        inplanetime: etime,
        inplanstate: this.inplanstate,
        companyid: this.companyid
        // inplandise: this.inplandise
      }
      getInspecplan(params).then(res => {
        if (res.status === '1') {
          this.planList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.planList = []
          this.total = 0
        }
      })
    },
    // 详情
    handleDetail (row) {
      this.detailShow = true
      this.detailInfo = row
      getplanDetails({inplanid: row.inplanid}).then(res => {
        if (res.status === '1') {
          if (res.data.length != 0) {
            this.inplanlist = res.data.inplaneuserlist
            this.inplanpoint = res.data.inplanpointlist[0].inequlistid
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 删除
    handleDelete (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        deleteInspecplan({ inplanid: row.inplanid }).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    // 停用
    handleStop (row) {
      updatePlandisa({inplanid: row.inplanid}).then(res => {
        if (res.status === '1') {
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.page = 1
      this.init()
    },
    handleReset () {
      this.page = 1
      this.inplanname = ''
      this.inplanstate = ''
      this.date = null
      this.init()
    },
    // 导出
    handleExport () {
      let staTime = ''
      let endTime = ''
      if (this.date != null) {
        staTime = this.date[0]
        endTime = this.date[1]
      }
      const params = {
        inplanname: this.inplanname,
        inplanstime: stime,
        inplanetime: etime,
        inplanstate: this.inplanstate,
      }
      onSiteExport(params).then(res => {
        if (res.status === '1') {
          this.$message.success(res.message)
          window.open(res.data.filepath)
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>
.alarm_box {
  width: 100%;
  height: 100%;
}
.alarm_table {
  margin-top: 20px;
}
.utF {
  margin: 0 16px;
}
.searchbtn {
  margin-left: 16px;
}
.cell{
  color: #42EEFF;
  cursor: pointer;
}
.contInput .el-input__inner{
  background: rgba(136,172,252,0.16);
  border: none;
  color: #A5B7CC;
}
.alarm_box  >>> .el-range-editor.el-input__inner {
  padding: 0 10px;
}
.plancont {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.plan_left,.plan_right{
  width: 50%;
  height: 100%;
  /* background: rgba(136,172,252,0.16); */
  box-sizing: border-box;
}
.plan_right{
  width: 45%;
  /* padding: 0 30px; */
}
.rig_title {
  margin-bottom: 10px;
}
.udiIP{
  width: 100%;
  height: 40px;
  line-height: 30px;
  align-items: center;
  background:rgba(136,172,252,0.16);
  color: #FFFFFF;
  margin-bottom: 8px;
  padding: 5px 12px;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 16px;
}
.user_list{
  width: 100%;
  height: 126px;
  overflow: auto;
  box-sizing: border-box;
  padding-right: 5px;
  scrollbar-width: none;
  display: flex;
  flex-wrap: wrap;
}
.plan_user::-webkit-scrollbar, .user_list::-webkit-scrollbar{/*滚动条整体样式*/
  width: 2px;     /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.plan_user::-webkit-scrollbar-thumb, .user_list::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
  background: #1B3255;
}
.plan_user::-webkit-scrollbar-track, .user_list::-webkit-scrollbar-track {/*滚动条里面轨道*/
  border-radius: 10px;
  background: #1B3255;
}
.udi_s {
  width: 90px;
  height: 40px;
  background:rgba(136,172,252,0.16);
  color: #42EEFF;
  border-radius: 4px;
  padding: 9px 12px;
  box-sizing: border-box;
  margin: 4px 3px;
  text-align: center;
}
.plan_point{
  width: 100%;
  height: calc(100% - 126px);
  margin-top: 2px;
}
.plan_info{
  width: 100%;
  height: calc(100% - 30px);
  padding-top: 12px;
}
.xj_use{
  width: 15%;
  text-align: right;
}
.xj_pro{
  width: 85%;
  padding-left: 5px;
  box-sizing: border-box;
}
.xj_pro >>> .el-progress-bar{
  padding-right: 50px;
}
.botCollapse, .botCollapse >>> .el-collapse-item__header,
.botCollapse >>> .el-collapse-item__wrap{
  border: none;
  background: transparent;
  color: #FFFFFF;
}
.botCollapse .btIco, .botCollapse >>> .el-collapse-item__arrow{
  font-size: 15px;
  font-weight: 800;
  margin-right: 6px;
}
.botCollapse .btIco{
  color: #42EEFF;
}
.botCollapse >>> .el-collapse-item__header{
  height: 30px;
  line-height: 30px;
  font-weight: 800;
  font-size: 14px;
}
.botCollapse >>> .el-collapse-item__content{
  padding-bottom: 0px;
}
.btc_cont{
  padding-left: 22px;
  box-sizing: border-box;
  font-size: 14px;
  color: #FFFFFF;
}
.btc_cont span{
  color: #A5B7CC;
}
.el-message-box {
  background: rgba(136,172,252,0.16);
}
.sr1, .sr2, .state1, .state2{ color: #42EEFF; }
.sr3, .sr4, .state3, .state4{ color: #F5222D; }
.sr5, .state5{
  color: #A5B7CC;
}
</style>
