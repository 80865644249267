// 用户管理
import { get, post, put, deletefn } from '@/http/http'

// 角色查询
export const roleQuery = p => get('v1/roleinfo/getroleinfo', p)

// 角色添加
export const roleAdd = p => post('v1/roleinfo/addroleinfo', p)

// 角色编辑
export const roleEdit = p => put('v1/roleinfo/updateroleinfo', p)

// 角色删除
export const roleDelete = p => deletefn('v1/roleinfo/deleteroleinfo', p)

// 用户查询
export const userQuery = p => get('v1/userinfo/getuserinfo', p)

// 用户新增
export const userAdd = p => post('v1/userinfo/adduserinfo', p)

// 用户修改
export const userEdit = p => put('v1/userinfo/updateuserinfo', p)

// 用户删除
export const userDelete = p => deletefn('v1/userinfo/deleteuserinfo', p)

// 用户重置密码
export const userPwdReset = p => put('v1/userinfo/resetuserinfo', p)

// 用户修改密码
export const userPwdChange = p => put('v1/userinfo/resetpassword', p)

// 用户停用启用
export const userUse = p => put('v1/userinfo/userenable', p)

// 客服区域划分下拉框
export const region = p => get('v1/customer/customeraddr', p)

// 定时刷新接口
export const refresh = p => post('v1/userinfo/is_flushed', p)
