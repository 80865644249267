<template>
  <div class="assign_cont">
    <el-form :model="form" :rules="formRules" ref="form" class="alarmHForm">
      <el-form-item label="分派方式" class="afItem" prop="disway">
        <div class="alarmRadio afInput" style="width: 500px;display:flex;padding-top: 4px;">
          <el-radio v-model="form.disway" label="1">平台通知</el-radio>
          <el-radio v-model="form.disway" label="2">短信通知</el-radio>
          <el-radio v-model="form.disway" label="3">语音通知</el-radio>
        </div>
      </el-form-item>
      <el-form-item label="接收人" class="afItem" prop="dealuser">
        <el-select v-model="form.dealuser" placeholder="请选择接收人" class="afInput">
          <el-option
              v-for="item in userList"
              :key="item.userid"
              :label="item.username"
              :value="item.userid">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="派单说明" prop="dismark" class="afItem">
        <textarea v-model="form.dismark" class="OperTextareas" placeholder="请输入派单说明" maxlength="150"></textarea>
        <p class="OperText"><span>{{ form.dismark.length }}</span>/150</p>
      </el-form-item>
      <div class="flexCE">
        <div class="afCancel" @click="handCancel()">取消</div>
        <div class="afConfirm" @click="handConfirm()">确定</div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { userQuery } from '@/http/api/user'
import { nowTime } from '@/utils/validate'
import { alarmAssign } from '@/http/api/alarm'
export default {
  props: ['alarmOperate'],
  data () {
    return {
      companyid: '',
      userList: [],
      form: {
        disway: '1',
        distime: '',
        dealuser: '',
        dismark: ''
      },
      formRules: {
        disway: [{ required: true, trigger: 'blur', message: '请选择分派方式' }],
        dealuser: [{ required: true, trigger: 'blur', message: '请选择接收人' }],
        dismark: [{ required: true, trigger: 'blur', message: '派单说明不能为空' }]
      }
    }
  },
  created () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    this.getUserList()
  },
  methods: {
    getUserList () {
      userQuery({ companyid: this.companyid }).then(res => {
        if (res.status === '1') {
          this.userList = res.data.data
        } else {
          this.userList = []
        }
      })
    },
    handCancel () {
      this.$refs.form.resetFields()
      this.$emit('assignHandCancel')
    },
    handConfirm () {
      this.$refs.form.validate((valid) => {
        if (!valid) return
        const params = {
          ectpid: this.alarmOperate.ectpid,
          dealuser: this.form.dealuser,
          distime: nowTime(),
          dismark: this.form.dismark,
          disway: this.form.disway
        }
        alarmAssign(params).then(res => {
          if (res.status === '1') {
            this.$emit('assignHandConfirm')
            this.$message.success(res.message)
            this.$refs.form.resetFields()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
