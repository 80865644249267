import { get, post, put, deletefn } from '@/http/http'

// 预案查询
export const getplanInfo = p => get('v1/planinfo/getplaninfo', p)

// 预案驳回
export const rejplan = p => put('v1/planinfo/rejplaninfo', p)

// 预案添加
export const planAdd = p => post('v1/planinfo/addplaninfo', p)

// 预案修改
export const planEdit = p => put('v1/planinfo/updateplaninfo', p)

// 预案删除
export const planDelete = p => deletefn('v1/planinfo/deleteplaninfo', p)
